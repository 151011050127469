import { navigate, useStaticQuery } from 'gatsby'
import React, { useEffect, useState } from 'react'
import Layout from 'src/components/Layout'
import { Hero } from '../sections/hero/_index'

import { Search } from '../../components/SearchList/_index'
import articles  from '../../assets/data/faq-babi/articles.json'
import { ArticlesModel } from '../types'
import { View } from 'src/types/View'

const SearchPage = () => {
  const [submitedSearch, setSubmitedSearch] = useState('')
  const [view, setView] = useState(View.Search)

  const contentMetas = {
    slug: '/search',
    metaTitle: 'Buscar | Ajuda Inter Co',
    metaDescription: '',
    noIndexOption: true,
    hideOpenAccountBottomForm: true,
    hideBabiChat: false,
  }

  useEffect(() => {
    if (typeof window === "undefined" || !window.document) {
      console.log(`bailing out of the useeffect. Going to continue to render??`)
      return
    }

    const querySearch = new URLSearchParams(document.location.search)
    const term = querySearch.get('term')

    const parseTerm = term?.split('-').join(' ')
    setSubmitedSearch(parseTerm || '')
  }, [])

  return (
    <Layout pageContext={contentMetas}>
      <Hero
        compactView
        useFullWidthSearchBar
        isHomePage={false}
        submitedSearch={submitedSearch}
        view={View.Search}
        hasHeaderTitle
      />
      <div style={{ marginTop: '24px' }}>
        <Search submitedSearch={submitedSearch} setView={setView} setSelectedTopic={() => {}}/>
      </div>
    </Layout>
  )
}
export default SearchPage