import React, { useEffect, useState } from 'react'
import ChevronMore from '@inter/icons/orangeds/MD/chevron-right'

import { TrackData } from '@interco/track'
import { navigate } from "gatsby"

import * as S from './style'

import type { Category } from '../../../../types'
import { normalizeString } from 'src/utils/normalizeString'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { usePreviewArticles } from 'src/hooks/data/usePreviewArticles'

type ShowMoreCardProps = {
  category: Category
}

export const ShowMoreCard = ({ category }: ShowMoreCardProps) => {
  const [sendDatalayerEvent] = useDataLayer()
  const [hidden, setHidden] = useState(true)
  const { previewArticles } = usePreviewArticles(category.id)
  const [chevronColor, setChevronColor] = useState('#161616')
  const parseTopic = normalizeString(category.title)

  return previewArticles.length > 0 && (
    <S.Card active={!hidden}>
      <S.FirstContent>
        <S.HeaderWrapper>
          <S.HeaderTitleWrapper>
            <S.HeaderTitle>
              {category.title}
            </S.HeaderTitle>
          </S.HeaderTitleWrapper>
          <S.Chevron active={!hidden} onClick={() => setHidden(!hidden)} className='d-md-none'>
            <ChevronMore color='#FF7A00' />
          </S.Chevron>
        </S.HeaderWrapper>
        {
          previewArticles.map(article => (
            <S.Topic
              onClick={() => TrackData.log({ conteudo: article.title, url: window.location.href, evento: 'click', categoria: category })}
              key={article.id}
              href={article.slug}
            >
              {article.title}
            </S.Topic>
          ))
        }
      </S.FirstContent>
      {(
        <S.ButtonShowMore
          className='d-none d-md-flex'
          onClick={() => {
            TrackData.log({ conteudo: 'Conferir mais artigos' })
            sendDatalayerEvent({
              section: 'dobra_2',
              element_action: 'click button',
              element_name: category.title + ' - Conferir mais artigos',
              section_name: 'Confira tudo sobre o Inter',
            })
            navigate(`/categories/${parseTopic}`)
          }}
          onMouseOver={() => setChevronColor('#FF7A00')}
          onMouseOut={() => setChevronColor('#161616')}
        >
          <p>Conferir mais artigos</p>
          <div className='chevron'>
            <ChevronMore color={chevronColor} />
          </div>
        </S.ButtonShowMore>
      )}

    </S.Card>
  )
}
