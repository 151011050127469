import React from "react";
import * as S from "./styles";
import { CategoryAccordion } from "./CategoryAccordion/CategoryAccordion";
import SubCategory from "src/models/SubCategory";

export type CategoriesSidebarProps = {
  listableCategories: ListableCategories
};


export type GroupedSubCategories = {
  categoryTitle: string;
  normalizedCategoryTitle: string;
  subCategories: SubCategory[];
}[]
export interface ListableCategories {
  sidebarTitle: string;
  subCategoriesByCategory: GroupedSubCategories;
}

export const CategoriesSidebar = ({
  listableCategories,
}: CategoriesSidebarProps) => {
  return (
    <S.CategorySidebar>
      <h2>{listableCategories.sidebarTitle}</h2>
      <S.CategoriesList>
        {listableCategories.subCategoriesByCategory.map((categoryItem) => {
          return (
            <CategoryAccordion
              key={categoryItem.normalizedCategoryTitle}
              title={categoryItem.categoryTitle}
              categorySubItems={categoryItem.subCategories}
              isOpenByDefault={categoryItem.categoryTitle === listableCategories.sidebarTitle}
              />
          );
        })}
      </S.CategoriesList>
    </S.CategorySidebar>
  );
};
