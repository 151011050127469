import { device } from "src/styles/breakpoints"
import { grayscale, orange, white } from "src/styles/colors"
import styled, { css } from "styled-components"

export const Section = styled.section`
  position: relative;
  padding-top: 16px;
  padding-bottom: 50px;

  @media ${device.tablet} {
    padding-top: 1px;
  }
`

export const SubcategoryHeader = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  padding: 12px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  p {
    margin-bottom: 0px !important;
  }

  &:hover {
    background: #F5F6FA;

    p {
      color: black !important;
    }
  }

  ${props => props.isActive && css`
    background: #FF7A00;
    transition: background 0.2s ease-in-out;
  
    p {
      color: white !important;
    }

    &:hover {
      background: #FF7A00;

      p {
        color: white !important;
      }
    }
  `}

`

export const Title = styled.p`
  margin-bottom: 0px !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  /* Neutral/Grayscale/500 */

  color: #161616;
`

export const SubItemList = styled.div<{ isActive: boolean }>`
  padding: 0px 0px 0px 24px;
`

export const SubcategoryItemList = styled.div`
  width: 100%;
  padding-bottom: 4px;
  margin-bottom: 4px;
`

export const SubcategoryItemListHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  padding: 16px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;

  &:hover {
    background: #F5F6FA;
  }
`

export const SubcategoryItemLisTitle = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */


  /* Neutral/Grayscale/500 */

  color: #161616;
`


export const DivisorDashed = styled.div`
  border: 1px dashed ${grayscale[200]};
  margin-bottom: 40px;
  margin-left: 24px;
  margin-right: 24px;
`


export const Card = styled.a`
  display: block;
  text-decoration: none;
  background: ${white};
  border-radius: 16px;
  margin-bottom: 40px;
  cursor: pointer;

  @media ${device.tablet} {
    padding: 24px;
    transition: all 0.15s ease-in-out;

    &:hover {
      background: ${grayscale[100]};
      text-decoration: none;
    }
  }
`

export const CardTitle = styled.h3`
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  color: ${orange.base};

  margin-bottom: 8px;
`

export const CardDescription = styled.div`

  

  margin-bottom: 16.5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  p, ul, li, strong, i, a, b {
    color: #000000 !important;
    font-size: 16px;
    line-height: 150%;
    font-style: normal;
    font-weight: normal;
  }
  
`

export const BreadCrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CardBreadCrumb = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[400]};

  margin-bottom: 0;
`

export const ViewMoreButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  background: none;
  border: none;
  outline: 0;
  font-family: 'Sora';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: ${orange.base};

  div {
    margin-top: 4px;
    animation: bounce 2.5s infinite;
  }

  @keyframes bounce {
    0%, 20%, 50%, 80%, 100% {-webkit-transform: translateY(0);}	
    40% {-webkit-transform: translateY(8px);}
    60% {-webkit-transform: translateY(4px);}
  } 

  &:focus {
    outline: 0;
  }
`

export const NotFoundArticle = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 350px;

  img {
    margin-bottom: 24px;
  }
`