import { useEffect, useState } from "react";
import { ArticlesSource } from "src/assets/ArticlesSource";
import { Article } from "src/pages/types";

export const usePreviewArticles = (categoryId: string, sliceSize:number = 3) => {
  const checkIfHasOrderItem = (a: Article, b: Article) => {
    if (a.order === null && b.order === null) {
      return 0;
    }
    if (a.order === null) {
      return 1;
    }
    if (b.order === null) {
      return -1;
    }

    return a.order - b.order;
  }

  const [previewArticles, setPreviewArticles] = useState<Article[]>([])
  useEffect(() => {
    const filteredArticles = ArticlesSource.getAll().filter(article => article.categoryId === categoryId).slice(0, sliceSize).sort(checkIfHasOrderItem)
    setPreviewArticles(filteredArticles)
  }, [categoryId])
  return { previewArticles }
}

