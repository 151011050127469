import { device } from "src/styles/breakpoints";
import { grayscale, orange } from "src/styles/colors";
import styled from "styled-components";

export const Divisor = styled.div`
  display: none;
  border: 1px solid ${grayscale[200]};
  margin-bottom: 21px;

  @media ${device.tablet} {
    display: flex;
  }
`

export const Back = styled.div`
  display: flex;
  align-items: center;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${orange.base};

  margin-bottom: 24px;
  cursor: pointer;
  width: fit-content;

  > div {
    margin-right: 4px;
    display: flex;
    align-items: center;
  }

  @media ${device.tablet} {
    margin-bottom: 52px;
  }
`

export const BreadCrumbWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const CardBreadCrumb = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${grayscale[400]};

  margin-bottom: 0;
`