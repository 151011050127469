import React, { useState, useEffect } from 'react'
import ChevronMore from '@inter/icons/orangeds/MD/chevron-down'


import useWidth from 'src/hooks/window/useWidth'

import { ShowMoreCard } from './components/ShowMoreCard/_index'

import { Chevron } from './components/ShowMoreCard/style'
import { Section, Title } from './style'

import type { Article, Category } from '../../types'
import CustomSelect from 'src/components/CustomSelect'

import { useCategoriesWithArticles } from 'src/hooks/data/useCategoriesWithArticles'

const WIDTH_MD = 768
const WIDTH_LG = 992

type ReadAllAboutInter = {
  setSelectedTopic: (data: Article) => void;
  sendDatalayerEvent: Function;
  setSelectedCategory: (data: string) => void;
}

enum SelectOptions {
  ATOZ = 'atoz',
  ZTOA = 'ztoa',
  DEFAULT = 'default'
}

export const ReadAllAboutInter = ({ setSelectedTopic, sendDatalayerEvent, setSelectedCategory }: ReadAllAboutInter) => {
  const [quantityToShow, setQuantityToShow] = useState(3)
  const [showAll, setShowAll] = useState(false)
  const [selectedOrder, setSelectedOrder] = useState(SelectOptions.DEFAULT)
  const categoriesWithArticles = useCategoriesWithArticles()
  const [categoriesToShow, setCategoriesToShow] = useState<Category[]>(categoriesWithArticles)

  const windowWidth = useWidth(300)

  useEffect(() => {
    if (windowWidth < WIDTH_MD) setQuantityToShow(3)
    if (windowWidth >= WIDTH_MD) setQuantityToShow(4)
    if (windowWidth >= WIDTH_LG) setQuantityToShow(6)
  }, [windowWidth])

  const options = [
    { text: 'Ordem padrão', value: 'default' },
    { text: 'Alfabetica: A-Z', value: 'atoz' },
    { text: 'Alfabetica: Z-A', value: 'ztoa' },
  ]

  const handleChangeOrder = (value: SelectOptions) => {
    setSelectedOrder(value)
  }

  useEffect(() => {
    if (selectedOrder === SelectOptions.ATOZ) {
      const aToZSortedCategories = categoriesToShow.sort((a, b) => a.title[0].localeCompare(b.title[0]));
      setCategoriesToShow([...aToZSortedCategories])
    }
    if (selectedOrder === SelectOptions.ZTOA) {
      const zToASortedCategories = categoriesToShow.sort((a, b) => a.title[0].localeCompare(b.title[0])).reverse();
      setCategoriesToShow([...zToASortedCategories])
    }

    if (selectedOrder === SelectOptions.DEFAULT) {
      setCategoriesToShow([...categoriesWithArticles])
    }
  }, [selectedOrder])

  return (
    <Section className='container pt-4 pb-4'>
      <div className='row d-flex justify-content-between align-items-center'>
        <div className='col-12 col-md-8'>
          <Title>Leia tudo sobre o Inter</Title>
        </div>
        <div className="col-12 col-md-4 col-xl-3">
          <CustomSelect onChange={handleChangeOrder} value={selectedOrder} options={options} />
        </div>
      </div>
      <div className='row'>
        {
          categoriesToShow.slice(0, showAll ? 999 : quantityToShow).map((category: Category) => (
            <div key={category.id} className='col-12 col-md-6 col-lg-4 mb-4'>
              <ShowMoreCard category={category} />
            </div>
          ))
        }
      </div>
      <div className='row'>
        <div className='col-12'>
          <Chevron active={showAll} onClick={() => setShowAll(!showAll)}>
            <ChevronMore color='#FF7A00' width={48} height={48} />
          </Chevron>
        </div>
      </div>
    </Section>
  )
}
