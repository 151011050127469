import { useArticlesCategories } from './useArticlesCategories'
import { CategoriesSource } from 'src/assets/CategoriesSource'
import { Category } from 'src/pages/types'

export const useCategoriesWithArticles = (): Category[] => {
  const articlesCategories = useArticlesCategories()
  const categories = CategoriesSource.getAll()
  const filteredCategories = categories.filter((category: Category) =>
    articlesCategories.some(articleCategory => articleCategory.categoryId === category.id)
  )

  return filteredCategories
}