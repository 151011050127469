import React from 'react'
import NewHeader from '@interco/institucional-frontend-lib-header/dist'
import Footer from '@interco/inter-frontend-lib-footer/lib/site'
import GlobalStyle from 'src/styles/global'
import SEO from '../Seo'
import Accessibility from '../Accessibility/Accessibility'

// Interfaces
interface ILayout {
  pageContext: {
    slug: string;
    metaTitle: string
    metaDescription: string
    noIndexOption?: boolean
    hideOpenAccountBottomForm?: boolean
    hideBabiChat: boolean;
  };
  children: React.ReactNode;
}

const Layout: React.FC<ILayout> = ({ children, pageContext }: ILayout) => {
  return (
    <>
      <Accessibility />
      <GlobalStyle />
      <SEO
        title={pageContext.metaTitle}
        description={pageContext.metaDescription}
        canonical={`${process.env.BASE_URL}${pageContext.slug}`}
      />
      <NewHeader theme='default'/>
      <main>{children}</main>
      <Footer isPT />
    </>
  )
}

export default Layout
