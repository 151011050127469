import React, { useEffect, useState } from 'react'
import * as S from './style'

import OrangeIcon from 'src/components/Icons'
import { Article } from 'src/pages/types';
import { normalizeString } from 'src/utils/normalizeString';
import { paginateHelper } from 'src/utils/paginateHelper'

import { getParameterByName } from 'src/utils/getParametersByName'
import image404 from 'assets/images/404.png'

type ArticlesByCategoriesProps = {
  categoryTitle: string
  categoryUrlParam: string
  articlesByCategory: any
}

const ArticlesByCategories = ({ categoryTitle, articlesByCategory }: ArticlesByCategoriesProps) => {
  const [page, setPage] = useState(1)
  const [articleByPage, setArticleByPage] = useState<Array<Article>>([])

  const term = getParameterByName('sub')

  const handleAddMore = () => setPage((prev) => prev + 1)

  useEffect(() => {
    if (term) {
      setPage(1)
      const subArticle = articlesByCategory?.filter(item => normalizeString(item.subcategoryTitle || "") === term)
      const pagedArticle = paginateHelper(subArticle, 10, 1)
      setArticleByPage(pagedArticle)
    } else {
      setPage(1)
      const pagedArticle = paginateHelper(articlesByCategory, 10, 1)
      setArticleByPage(pagedArticle)
    }
  }, [articlesByCategory, term])

  useEffect(() => {
    if (term) {
      const subArticle = articlesByCategory?.filter(item => normalizeString(item.subcategoryTitle || "") === term)

      if (page === 1) {
        const pagedArticle = paginateHelper(subArticle, 10, 1)
        setArticleByPage(pagedArticle)
      } else {
        const pagedArticle = paginateHelper(subArticle, 10, page)
        setArticleByPage(prevArticles => {
          return [...prevArticles, ...pagedArticle]
        })
      }
    } else {
      if (page === 1) {
        const pagedArticle = paginateHelper(articlesByCategory, 10, 1)
        setArticleByPage(pagedArticle)
      } else {
        const pagedArticle = paginateHelper(articlesByCategory, 10, page)
        setArticleByPage(prevArticles => {
          return [...prevArticles, ...pagedArticle]
        })
      }
    }
  }, [page])

  return (
    <>
      {articleByPage.length === 0 && (
        <S.NotFoundArticle>
          <img src={image404} width={220} height={220} />
          Não encontramos artigos relacionados a esse tópico.
        </S.NotFoundArticle>
      )}
      {articleByPage?.map((item: Article) => (
        <div key={item.slug}>
          <S.Card
            href={`/${item.slug}`}
          >
            <S.CardTitle>{item.title}</S.CardTitle>
            <S.CardDescription dangerouslySetInnerHTML={{ __html: item.content }}></S.CardDescription>
            <S.BreadCrumbWrapper>
              <S.CardBreadCrumb>Central de Relacionamento</S.CardBreadCrumb>
              <div>
                <OrangeIcon size='SM' color='#6A6C72' icon='chevron-right' />
              </div>
              <S.CardBreadCrumb>{categoryTitle}</S.CardBreadCrumb>
            </S.BreadCrumbWrapper>
          </S.Card>
          <S.DivisorDashed />
        </div>
      ))}
      {articleByPage.length >= 10 && (
        <S.ViewMoreButton onClick={handleAddMore}>
          Ver mais artigos
          <div>
            <OrangeIcon size='MD' color='#ff7a00' icon='chevron-down' />
          </div>
        </S.ViewMoreButton>
      )}
    </>
  )
}

export default ArticlesByCategories
