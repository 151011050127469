import React, { useEffect, useState } from 'react'
import ChevronMore from '@inter/icons/orangeds/MD/chevron-down'

import OpenVideo from 'src/components/OpenVideo'
import useWidth from 'src/hooks/window/useWidth'
import videos from 'src/assets/data/faq-babi/videos.json'
import categories from 'src/assets/data/faq-babi/categories.json'

import Target from '../../../assets/images/target.png'
import { Category, VideosModel, VideosPropsModel } from '../../types'
import { Carousel } from './carousel/_index'

import {
  Wrapper,
  Section,
  ContentWrapper,
  VideoWrapper,
  Divisor,
  Chevron,
} from './style'

const WIDTH_LG = 992

export const Playlist = () => {

  const windowWidth = useWidth(300)
  const [ qttShow, setQttShow ] = useState(1)

  const categoriesList: Category[] = categories.pt
  const videosList: VideosModel[] = videos as VideosModel[]

  const getTumbnail = (url: string): string => {
    const splitedUrl = url.split('/')

    return splitedUrl[splitedUrl.length - 1]
  }

  const getCategoryName = (id: string): string => {
    const categoryName = categoriesList.find(
      (category: Category) => category.uuid === id,
    )

    return categoryName?.title || ''
  }

  return (
    <Wrapper>
      <Section className='container p-5'>
        <div className='row'>
          <div className='col-12'>
            {videosList.slice(0, qttShow).map((item: VideosModel) => (
              <>
                <Carousel
                  show={windowWidth >= WIDTH_LG ? 3 : 2}
                  title={getCategoryName(item.category_id)}
                  key={item.id}
                >
                  {item.videos.map((video: VideosPropsModel, index: number) => (
                    <ContentWrapper key={index}>
                      <OpenVideo link={video.url}>
                        <VideoWrapper
                          video={`https://img.youtube.com/vi/${getTumbnail(
                            video.url,
                          )}/hqdefault.jpg`}
                        >
                          <img src={Target} />
                          <span>{video.durationTime}</span>
                        </VideoWrapper>
                        <p>{video.title}</p>
                      </OpenVideo>
                    </ContentWrapper>
                  ))}
                </Carousel>
                {qttShow > 1 && <Divisor />}
              </>
            ))}
            {videosList.length > 1 && <Chevron
              active={qttShow > 1}
              onClick={() => setQttShow(qttShow === 1 ? 999 : 1)}
            >
              <ChevronMore color='#FF7A00' width={48} height={48}/>
            </Chevron>}
          </div>
        </div>
      </Section>
    </Wrapper>
  )
}
