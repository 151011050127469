import NewHeader from '@interco/institucional-frontend-lib-header/dist'
import Footer from '@interco/inter-frontend-lib-footer/lib/site'
import { TrackData } from '@interco/track'
import { navigate, PageProps } from 'gatsby'
import React, { useCallback, useEffect, useState } from 'react'
import OrangeIcon from 'src/components/Icons'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Hero } from 'src/pages/sections/hero/_index'
import GlobalStyle from 'src/styles/global'
import categoriesJSON from '../../assets/data/faq-babi/categories.json'
import type { Article } from '../../pages/types'
import * as S from './styles'

import { useForm } from 'react-hook-form'
import { Search } from 'src/components/SearchList/_index'
import SEO from 'src/components/Seo'
import { View } from 'src/types/View'


type PageContext = {
  article: Article;
}

type ArticleViewProps = {
  pageContext: PageContext;
} & PageProps

enum UsefullReaction {
  DISLIKE = 'dislike',
  LIKE = 'like'
}

enum RadiusFormData {
  INSTRUCTIONSNOTBEEASY = 'As instruções não eram claras',
  LIKEARTICLEBUTNEEDBEBETTER = 'Gostei do artigo, mas o processo pode melhorar',
  NOTRELATIONWITHTOPIC = 'As  informações estão desatualizadas/incorretas',
  OTHER = 'Outra'
}

const ArticleView = ({ pageContext }: ArticleViewProps) => {
  const { article: selectedArticle } = pageContext
  const [view, setView] = useState(View.Topic)
  const [submitedSearch, setSubmitedSearch] = useState('')
  const [showDislikeForm, setShowDislikeForm] = useState(false)

  const { register, handleSubmit } = useForm({ defaultValues: { 'selected-option': null, suggestion: '' } })
  const [sendDatalayerEvent] = useDataLayer()

  const [usefullReaction, setUsefullReaction] = useState<UsefullReaction | null>(null)

  const findCategory = categoriesJSON.pt.find(item => item.id === pageContext.article.category_id)?.title ?? ''

  let likeArticles: String[] = []
  let dislikeArticles: String[] = []
  const localStorageName = 'articles_userfull_reaction'

  TrackData.initOrUpdate({
    runtimeEnv: 'production',
    projectName: 'ajuda_inter_co',
  })


  const getLocalStorageItem = () => {
    let getItem = localStorage.getItem(localStorageName)

    if (!getItem) {
      localStorage.setItem(localStorageName, JSON.stringify({ likeArticles: [], dislikeArticles: [] }))
    }

    getItem = localStorage.getItem(localStorageName)

    return JSON.parse(getItem!)
  }

  const contentMetas = {
    slug: selectedArticle.slug,
    metaTitle: `${pageContext.article.title}`,
    metaDescription: 'A parceria que você precisa pra chegar onde quiser.',
    hideBabiChat: true,
  }

  const handleLikeButton = useCallback(() => {
    handleSaveToLocalStorageByType(pageContext.article.id, UsefullReaction.LIKE)
    TrackData.log({ conteudo: pageContext.article.title, evento: 'like', url: window.location.href, categoria: findCategory })
  }, [])

  const handleDislikeButton = useCallback(() => {
    setShowDislikeForm(true)
  }, [])


  const handleSaveToLocalStorageByType = async (articleId: string, type: UsefullReaction) => {
    if (type === UsefullReaction.LIKE) {
      const findArticle = likeArticles.find(item => item === articleId)
      const checkIfDislikeArticleBefore = dislikeArticles.find(item => item === articleId)

      if (checkIfDislikeArticleBefore) {
        let filteredArticles = dislikeArticles.filter(item => item !== articleId)
        dislikeArticles = filteredArticles
      }

      if (findArticle) {
        setUsefullReaction(UsefullReaction.LIKE)
        return
      }

      likeArticles.push(articleId)
      setUsefullReaction(UsefullReaction.LIKE)
      localStorage.setItem(localStorageName, JSON.stringify({ likeArticles, dislikeArticles }))
      return
    }

    if (type === UsefullReaction.DISLIKE) {
      const findArticle = dislikeArticles.find(item => item === pageContext.article.id)
      const checkIfLikeArticleBefore = likeArticles.find(item => item === pageContext.article.id)

      if (checkIfLikeArticleBefore) {
        let filteredArticle = likeArticles.filter(item => item !== articleId)
        likeArticles = filteredArticle
      }

      if (findArticle) {
        setUsefullReaction(UsefullReaction.DISLIKE)
        return
      }

      dislikeArticles.push(articleId)
      setUsefullReaction(UsefullReaction.DISLIKE)
      localStorage.setItem(localStorageName, JSON.stringify({ likeArticles, dislikeArticles }))
      return
    }
  }


  const submitForm = (data: { 'selected-option': string, suggestion?: string }) => {
    handleSaveToLocalStorageByType(pageContext.article.id, UsefullReaction.DISLIKE)
    TrackData.log({
      conteudo: pageContext.article.title,
      evento: 'unlike',
      url: window.location.href,
      categoria: findCategory,
      melhoria: data['selected-option'],
      sugestao: data.suggestion || ''
    })
  }

  useEffect(() => {
    const getLocalStorage = getLocalStorageItem()

    likeArticles = getLocalStorage.likeArticles
    dislikeArticles = getLocalStorage.dislikeArticles
    console.log(pageContext.article.id)
  }, [pageContext])

  useEffect(() => {
    const checkIfLikeArticleBefore = likeArticles?.find(item => item === pageContext.article.id)
    const checkIfDislikeArticleBefore = dislikeArticles?.find(item => item === pageContext.article.id)

    if (checkIfLikeArticleBefore) {
      return setUsefullReaction(UsefullReaction.LIKE)
    }

    if (checkIfDislikeArticleBefore) {
      return setUsefullReaction(UsefullReaction.DISLIKE)
    }
  }, [likeArticles, dislikeArticles])

  return (
    <>
      <GlobalStyle />
      <SEO
        title={contentMetas.metaTitle}
        description={contentMetas.metaDescription}
        canonical={`${process.env.BASE_URL}${contentMetas.slug}`}
      />
      <NewHeader theme='default' />
      <main>
        <Hero
          useFullWidthSearchBar
          compactView={true}
          submitedSearch={submitedSearch}
          hasHeaderTitle={false}
          view={view}
          isHomePage={false}
        />
        {view === View.Topic && (
          <S.Section className='container pt-5'>
            <div className='row'>
              <div className='col-12'>
                <S.BreadCrumb>
                  <a href='/'>Central de Relacionamento</a>
                  <div>
                    <OrangeIcon size='SM' color='#161616' icon='arrow-right' />
                  </div>
                  <p>{pageContext.article.title}</p>
                </S.BreadCrumb>
                <S.Divisor />
                <S.Back onClick={() => navigate('/')}>
                  <div>
                    <OrangeIcon size='MD' color='#ff7a00' icon='chevron-left' />
                  </div>
                  Voltar
                </S.Back>
              </div>
            </div>
            <div className='row'>
              <div className='col-12 mx-lg-auto col-lg-9'>
                <S.Title>{pageContext.article.title}</S.Title>
                <S.Description dangerouslySetInnerHTML={{ __html: pageContext.article.content }} />
                {usefullReaction !== null ? (
                  <S.FeedbackSUccess>
                    <OrangeIcon size='MD' color='#999' icon={'like'} />
                    <p>Agradecemos pelo feedback!</p>
                  </S.FeedbackSUccess>

                ) : (
                  <>
                    <S.Feedback>
                      <p>Essa resposta foi útil?</p>
                      <div>
                        <S.FeedBackButton className='icon' onClick={handleLikeButton}>
                          <OrangeIcon size='MD' color='#00AA4F' icon={usefullReaction === UsefullReaction.LIKE ? 'like' : 'like-outline'} />
                        </S.FeedBackButton>
                        <S.FeedBackButton className='icon' onClick={handleDislikeButton}>
                          <OrangeIcon size='MD' color='#F56A50' icon={showDislikeForm ? 'dislike' : 'dislike-outline'} />
                        </S.FeedBackButton>
                      </div>
                    </S.Feedback>
                    {showDislikeForm && (
                      <S.FormContainer onSubmit={handleSubmit(submitForm)}>
                        <S.FormTitle>O que podemos melhorar?</S.FormTitle>
                        <S.RadiusSelection htmlFor={RadiusFormData.INSTRUCTIONSNOTBEEASY}>
                          <input id={RadiusFormData.INSTRUCTIONSNOTBEEASY} type='radio' value={RadiusFormData.INSTRUCTIONSNOTBEEASY} name="selected-option" ref={register} />
                          {RadiusFormData.INSTRUCTIONSNOTBEEASY}
                        </S.RadiusSelection>
                        <S.RadiusSelection htmlFor={RadiusFormData.LIKEARTICLEBUTNEEDBEBETTER}>
                          <input id={RadiusFormData.LIKEARTICLEBUTNEEDBEBETTER} type='radio' value={RadiusFormData.LIKEARTICLEBUTNEEDBEBETTER} name="selected-option" ref={register} />
                          {RadiusFormData.LIKEARTICLEBUTNEEDBEBETTER}
                        </S.RadiusSelection>
                        <S.RadiusSelection htmlFor={RadiusFormData.NOTRELATIONWITHTOPIC}>
                          <input id={RadiusFormData.NOTRELATIONWITHTOPIC} type='radio' value={RadiusFormData.NOTRELATIONWITHTOPIC} name="selected-option" ref={register} />
                          {RadiusFormData.NOTRELATIONWITHTOPIC}
                        </S.RadiusSelection>
                        <S.RadiusSelection htmlFor={RadiusFormData.OTHER}>
                          <input id={RadiusFormData.OTHER} type='radio' value={RadiusFormData.OTHER} name="selected-option" ref={register} />
                          Outra
                        </S.RadiusSelection>
                        <S.TextAreaContainer>
                          <S.TextAreaTitle>Diga-nos o que podemos melhorar</S.TextAreaTitle>
                          <textarea placeholder='(opcional)' name="suggestion" ref={register} />
                        </S.TextAreaContainer>
                        <S.SendButtonForm type='submit'>Enviar</S.SendButtonForm>
                      </S.FormContainer>
                    )}
                  </>
                )}
              </div>
            </div>
          </S.Section>
        )}
        {view === View.Search && (
          <Search
            submitedSearch={submitedSearch}
            setView={setView} setSelectedTopic={() => { }} />
        )}
      </main>
      <Footer isPT />
    </>
  )
}

export default ArticleView
