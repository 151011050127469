import React, { useState } from "react";
import { normalizeString } from "src/utils/normalizeString";
import * as S from "./styles";
import { navigate } from "gatsby";
import OrangeIcon from "../../Icons";
import SubCategory from "src/models/SubCategory";

type CategoryAccordionProps = {
  title: string;
  categorySubItems: SubCategory[];
  isOpenByDefault?: boolean;
}

function removeEmojiFromString(text: string) {
  const emojiPattern =
    /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g;
  const trailingSpacePattern = /\s+$/g;

  return text
    .replace(emojiPattern, "")
    .replace(trailingSpacePattern, "");
}

export const CategoryAccordion = ({
  title,
  categorySubItems,
  isOpenByDefault = false,
}: CategoryAccordionProps) => {
  const normalizedTitle = normalizeString(title);
  const [isAccordionOpen, setIsAccordionOpen] = useState(isOpenByDefault);
  const itemHasSubcategories = categorySubItems.length > 0;
  if (!itemHasSubcategories) return null


  const toggleAccordion = () => {
    if (!itemHasSubcategories) {
      return navigate(`/categories/${normalizedTitle}`);
    }
    setIsAccordionOpen(!isAccordionOpen);
  };

  const handleSubCategoryClick = (subtitle: string) => {
    const removeEmoji = removeEmojiFromString(subtitle);
    const normalizedSubCategory = normalizeString(removeEmoji);

    navigate(`/categories/${normalizedTitle}?sub=${normalizedSubCategory}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <S.Accordion>
      <S.CategoryAccordionHeader onClick={toggleAccordion} isActive={false}>
        <S.Title>{title}</S.Title>
        <div>
          <OrangeIcon
            size="MD"
            color="#ff7a00"
            icon={isAccordionOpen ? "chevron-up" : "chevron-down"}
          />
        </div>
      </S.CategoryAccordionHeader>
      {itemHasSubcategories && isAccordionOpen && (
        <S.SubCategoryList>
          {categorySubItems?.map((subItem) => (
            <S.SubCategoryItem
              key={subItem.uuid}
              onClick={() => handleSubCategoryClick(subItem.title)}
            >
              <S.SubCategoryItemHeader>
                <S.SubcategoryItemLisTitle>
                  {removeEmojiFromString(subItem.title)}
                </S.SubcategoryItemLisTitle>
              </S.SubCategoryItemHeader>
            </S.SubCategoryItem>
          ))}
        </S.SubCategoryList>
      )}
    </S.Accordion>
  );
};
